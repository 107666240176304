import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function AuthErrorComponent({
	errorMessage,
}: {
	errorMessage: string | undefined;
}) {
	const [showLoginErrorComponent, setShowLoginErrorComponent] =
		useState<Boolean>(false);

	useEffect(() => {
		if (errorMessage !== undefined) {
			setShowLoginErrorComponent(true);
		}
	}, [errorMessage]);

	function handleCloseLoginErrorComponent() {
		setShowLoginErrorComponent(false);
		const passwordTag = document.getElementById("password") as HTMLInputElement;
		if (passwordTag !== undefined && passwordTag !== null) {
			passwordTag.value = "";
		}
	}
	return (
		<>
			{showLoginErrorComponent && (
				<span className='h-[60px] flex items-center justify-center px-2  w-[320px] bg-red-950 rounded-md bg-[#141218] border-red-600 border shadow shadow-xl shadow-black'>
					<span className='flex-wrap'>{errorMessage}</span>
					<button role="button" aria-label="Error modal close button" onClick={handleCloseLoginErrorComponent}>
						<CloseIcon className='text-3xl' />
					</button>
				</span>
			)}
		</>
	);
}
