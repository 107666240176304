"use client";

import { useFormState, useFormStatus } from "react-dom";
import { authenticate } from "../lib/actions";
import AuthErrorComponent from '../components/auth/auth-component';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoginComponent({ logout }: any) {
	const [errorMessage, dispatch] = useFormState(authenticate, undefined);
	const [loadingPage, setLoadingPage] = useState<boolean>(false);

	return (
		<section className='grid text-md grid-cols-1 gap-4 bg justify-items-center min-h-screen content-center ' >
			<div className='p-2 min-w-[320px] rounded-md bg-indigo-800  flex items-center justify-center'>
				<span className='font-light text-xl'>Covalence Login</span>
			</div>
			<AuthErrorComponent errorMessage={errorMessage} />
			{
				loadingPage && <CircularProgress />
			}
			<form
				className='max-h-[900px] h-[580px] grid grid-cols-1 gap-4 bg-gradient-to-t from-slate-950 to-indigo-800 p-4 rounded-md max-w-[720px] min-w-[320px] justify-items-center shadow shadow-lg shadow-slate-950  background-animate'
				action={dispatch}
			>
				<label htmlFor='email'>Email or Phone</label>
				<input
					type='text'
					className='defaultInputStyling'
					id='email'
					name='email'
					placeholder='Enter email or phone'
					required
				/>

				<label htmlFor='password'>Password</label>
				<input
					type='password'
					className='defaultInputStyling'
					id='password'
					name='password'
					placeholder='Enter password'
					required
				/>

				<LoginButton setLoadingPage={setLoadingPage} />

				<a href='#' className='text-white underline mt-8'>
					Forgot your email or password?
				</a>
				<span className='text-white min-w-full my-4 border-t border-white'></span>
				<span> Dont have an account? </span>
				<a href='/signup' className='text-white underline text-lg'>
					Click here to signup
				</a>
			</form>
		</section>
	);
}

function LoginButton({ setLoadingPage }: any) {
	const { pending } = useFormStatus();

	const handleClick = (event: { preventDefault: () => void; }) => {
		setLoadingPage(true);
		if (pending) {
			event.preventDefault()
		}
	}

	return (
		<button
			aria-disabled={pending}
			id="loginButton"
			name="loginButton"
			role="button" aria-label="Login to application button"
			type='submit'
			className="defaultButtonStyling"
			onClick={handleClick}
		>
			Login
		</button>
	);
}


